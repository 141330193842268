<template>
  <b-sidebar
    id="add-internal-link-account"
    body-class="custom-scroll"
    v-model="showInternalLinkAccountSideBar"
    aria-labelledby="Add/Edit link account"
    aria-label="Add/Edit link account"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    lazy
    backdrop
    right
    width="70vw"
    @hidden="sideBarHidden"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0">Add new link account</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          aria-labelledby="Add link account title"
          @click="
            hide;
            $emit('closeSideBar');
          "
          ><img src="../../assets/img/close.png" alt="close-tab"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <b-form
        id="link-account-form"
        name="link-account-form"
        v-if="showForm"
        autocomplete="off"
        novalidate
      >
        <b-form-row>
          <b-col sm="12" md="6" lg="5">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  id="link-account-name"
                  :class="{
                    'is-invalid':
                      !linkAccountDetails.accountName && formSubmitted,
                  }"
                  type="text"
                  placeholder=" "
                  v-model="linkAccountDetails.accountName"
                  required
                ></b-form-input>
                <label for="link-account-name">Link account name</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!linkAccountDetails.accountName && formSubmitted"
                  >Link account name required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="5" lg="4" class="pt-md-2 mt-md-1">
            <b-form-group>
              <b-form-checkbox
                id="property-dependent"
                name="property-dependent"
                :disabled="isAddressSetupDone"
                v-model="linkAccountDetails.isForEachProperty"
              >
                Property dependent
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="isAddressSetupDone">
          <b-col>
            <h6 class="font-14">
              <InfoCircelYellow class="mr-1 mt-n1" />
              {{ DISPLAY_MESSAGES.ADDRESS_SETUP_NOT_DONE_LINK_ACCOUNT_ERROR }}
            </h6>
          </b-col>
        </b-form-row>
        <div>
          <b-form-row>
            <b-col sm="12" md="6" lg="5">
              <b-form-group>
                <div class="floating-input-field">
                  <b-form-input
                    :id="`field-name`"
                    :class="{
                      'is-invalid': !fieldsObject.name && addFieldSubmit,
                    }"
                    type="text"
                    placeholder=" "
                    v-model="fieldsObject.name"
                    required
                  ></b-form-input>
                  <label :for="`field-name`">Field name</label>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="!fieldsObject.name && addFieldSubmit"
                    >Field name required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="5">
              <b-form-group>
                <div class="floating-select-field">
                  <b-form-select
                    :id="`field-type`"
                    class="form-control"
                    v-model="fieldsObject.fieldType"
                    :class="{
                      'is-value-exist': fieldsObject.fieldType != null,
                      'is-invalid': !fieldsObject.fieldType && addFieldSubmit,
                    }"
                  >
                    <b-form-select-option
                      :key="i"
                      :value="type"
                      v-for="(type, i) in linkAccountFieldTypeOptions"
                      >{{ type }}</b-form-select-option
                    >
                  </b-form-select>
                  <label :for="`field-type`">Field type</label>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="!fieldsObject.fieldType && addFieldSubmit"
                    >Field type required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" md="6" lg="5">
              <b-form-group>
                <div class="floating-input-field">
                  <b-form-input
                    :id="`parameter-name`"
                    :class="{
                      'is-invalid': !fieldsObject.paramName && addFieldSubmit,
                    }"
                    type="text"
                    placeholder=" "
                    v-model="fieldsObject.paramName"
                  ></b-form-input>
                  <label :for="`parameter-name`">Parameter name</label>
                  <b-form-invalid-feedback
                    class="d-block"
                    v-if="!fieldsObject.paramName && addFieldSubmit"
                    >Parameter name required.</b-form-invalid-feedback
                  >
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="5">
              <b-form-group>
                <div class="floating-input-field">
                  <b-form-input
                    :id="`helper-text`"
                    type="text"
                    placeholder=" "
                    v-model="fieldsObject.helperTxt"
                  ></b-form-input>
                  <label :for="`helper-text`">Helper text</label>
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-4">
            <b-col>
              <b-button
                type="button"
                v-activeBlur
                variant="secondary"
                @click="addEditFieldToLinkAccount"
                >{{ editFieldIndex > -1 ? "Edit field" : "Add field" }}
              </b-button>
              <b-button
                type="button"
                v-activeBlur
                @click="resetField"
                class="ml-3"
                variant="outline-secondary"
                >Reset</b-button
              >
            </b-col>
          </b-form-row>
        </div>
        <b-form-row>
          <b-col sm="12" lg="10">
            <div class="b-table-sticky-header custom-scroll">
              <table class="table">
                <thead>
                  <tr>
                    <th>Field name</th>
                    <th>Field type</th>
                    <th>Parameter name</th>
                    <th>Helper text</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="linkAccountDetails.fields.length">
                    <tr
                      v-for="(field, fIndex) in linkAccountDetails.fields"
                      :key="fIndex"
                    >
                      <td>{{ field.name }}</td>
                      <td>{{ field.fieldType }}</td>
                      <td>{{ field.paramName }}</td>
                      <td>{{ field.helperTxt }}</td>
                      <td class="text-right pr-0 py-2">
                        <b-dropdown
                          boundary="window"
                          class="action-dropdown"
                          toggle-class="btn-h-44"
                          no-caret
                          variant="outline-secondary"
                          right
                          no-flip
                        >
                          <template #button-content>
                            Actions
                            <BIconChevronDown
                              class="pt-1"
                              variant="dark"
                              scale="0.65"
                            />
                          </template>
                          <b-dropdown-item
                            title="Edit chart"
                            href="javascript:void(0)"
                            @click="editField(field, fIndex)"
                            >Edit</b-dropdown-item
                          >
                          <b-dropdown-item
                            title="Delete chart"
                            href="javascript:void(0)"
                            @click="deleteField(fIndex)"
                            >Delete</b-dropdown-item
                          >
                        </b-dropdown>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="5" class="text-center">
                        No fields available.
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-form-invalid-feedback
              class="d-block mb-2"
              v-if="!linkAccountDetails.fields.length && formSubmitted"
              >Atleast one field required.</b-form-invalid-feedback
            >
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="12" lg="10">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  id="end-point-to-validate"
                  :class="{
                    'is-invalid':
                      (!linkAccountDetails.authEndPoint && formSubmitted) ||
                      (!validateUrl(linkAccountDetails.authEndPoint) &&
                        formSubmitted),
                  }"
                  type="text"
                  placeholder=" "
                  v-model="linkAccountDetails.authEndPoint"
                  required
                ></b-form-input>
                <label for="end-point-to-validate"
                  >Endpoint to validate crendentials</label
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!linkAccountDetails.authEndPoint && formSubmitted"
                  >Endpoint to validate crendentials
                  required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    linkAccountDetails.authEndPoint &&
                    formSubmitted &&
                    !validateUrl(linkAccountDetails.authEndPoint)
                  "
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="12" lg="10">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  id="unlink-end-point-to-validate"
                  :class="{
                    'is-invalid':
                      (!linkAccountDetails.unLinkEndPoint && formSubmitted) ||
                      (!validateUrl(linkAccountDetails.unLinkEndPoint) &&
                        formSubmitted),
                  }"
                  type="text"
                  placeholder=" "
                  v-model="linkAccountDetails.unLinkEndPoint"
                ></b-form-input>
                <label for="unlink-end-point-to-validate"
                  >Endpoint to unlink account</label
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!linkAccountDetails.unLinkEndPoint && formSubmitted"
                  >Endpoint to unlink account required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    linkAccountDetails.unLinkEndPoint &&
                    formSubmitted &&
                    !validateUrl(linkAccountDetails.unLinkEndPoint)
                  "
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
      <TestLinkAccountAPISideBar
      :showTestLinkAccountAPISideBar="showTestLinkAccountAPISideBar"
      :linkAccountDetails="linkAccountDetails"
      @closeSideBar="showTestLinkAccountAPISideBar = false"
    />
      <ConfirmationModal
        :showModal="showDeleteFieldConfirmModal"
        :title="`CONFIRM`"
        message="LINK_ACCOUNT_FIELD_REMOVE"
        @onConfirm="confirmDeleteField"
        @closeConfirmModal="showDeleteFieldConfirmModal = false"
      />
    </template>
    <template #footer="{}">
      <b-button
        type="button"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="onResetLinkAccount"
        >Reset</b-button
      >
      <b-button
        type="button"
        v-activeBlur
        :disabled="saveLoadingIcon"
        @click="onSubmitLinkAccount"
        variant="primary"
        class="ml-3 btn-h-44"
        >Save
        <b-spinner
          v-if="saveLoadingIcon"
          label="Spinning"
          small
          class="ml-2"
        ></b-spinner>
      </b-button>
      <b-button
        type="button"
        v-activeBlur
        variant="secondary"
        :disabled="!linkAccountDetails.fields.length"
        @click="showTestLinkAccountAPISideBar = true"
        aria-controls="test-link-account-api-sidebar"
        :aria-expanded="showTestLinkAccountAPISideBar"
        class="ml-3 btn-h-44"
        >Test API
      </b-button>
    </template>
  </b-sidebar>
</template>
<script>
import { mapState } from 'vuex'
import { BIconChevronDown } from 'bootstrap-vue'
import {
  LinkAccountFieldTypeOptions,
  DISPLAY_MESSAGES
} from '../../utilities/constants'
import { useValidateFields } from '@/composables/useValidateFields'
import { cloneDeep } from 'lodash'
import InfoCircelYellow from '../../assets/svg/info-circle-yellow.svg'

export default {
  name: 'AddEditInternalLinkAccountSideBar',
  props: ['showInternalLinkAccountSideBar'],
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  components: {
    BIconChevronDown,
    InfoCircelYellow,
    ConfirmationModal: () => import('../common/ConfirmationModal.vue'),
    TestLinkAccountAPISideBar: () => import('./test-link-account-api/TestLinkAccountAPISideBar.vue')
  },
  data () {
    return {
      isEditPage: false,
      cloneLinkAccountDetails: null,
      linkAccountDetails: {
        tenantId: null,
        accountName: null,
        authEndPoint: null,
        unLinkEndPoint: null,
        isForEachProperty: false,
        fields: []
      },
      linkAccountDetailsLoading: false,
      showForm: true,
      formSubmitted: false,
      saveLoadingIcon: false,
      addFieldSubmit: false,
      showDeleteFieldConfirmModal: false,
      editFieldIndex: -1,
      fieldsObject: {
        name: null,
        fieldType: null,
        paramName: null,
        helperTxt: null,
        id: 0,
        linkAccountId: 0
      },
      linkAccountFieldTypeOptions: LinkAccountFieldTypeOptions,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      showTestLinkAccountAPISideBar: false
    }
  },
  computed: {
    isAddressSetupDone () {
      return !!(this.selectedTenant.length &&
        !this.selectedTenant[0].isAddressSetupDone)
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  created () {
    if (this.$route.name === 'Settings-edit-link-account') {
      this.isEditPage = true
      this.getLinkAccountDetails()
    }
  },
  mounted () {
    this.cloneLinkAccountDetails = cloneDeep(this.linkAccountDetails)
    this.setPropertyDependent()
  },
  methods: {
    showToaster (message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type
      })
    },
    getLinkAccountDetails () {
      this.linkAccountDetailsLoading = true
      const postObj = {
        linkAccountId: this.$route.params.id,
        authenticationType: 'link-account'
      }
      this.$store
        .dispatch('settings/getLinkAccountDetails', postObj)
        .then((response) => {
          this.linkAccountDetailsLoading = false
          this.linkAccountDetails = response.data
          this.cloneLinkAccountDetails = cloneDeep(response.data)
        })
        .catch(() => {
          this.linkAccountDetailsLoading = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger'
          })
        })
    },
    addEditFieldToLinkAccount () {
      this.addFieldSubmit = true
      if (
        this.fieldsObject.name &&
        this.fieldsObject.fieldType &&
        this.fieldsObject.paramName
      ) {
        if (this.editFieldIndex > -1) {
          this.linkAccountDetails.fields[this.editFieldIndex] =
            this.fieldsObject
        } else {
          this.linkAccountDetails.fields.push(this.fieldsObject)
        }
        this.fieldsObject = {
          name: null,
          fieldType: null,
          paramName: null,
          helperTxt: null,
          id: 0,
          linkAccountId: 0
        }
        this.resetField()
        this.addFieldSubmit = false
      }
    },
    resetField () {
      this.addFieldSubmit = false
      this.fieldsObject = {
        name: null,
        fieldType: null,
        helperTxt: null,
        paramName: null,
        id: 0,
        linkAccountId: 0
      }
      this.editFieldIndex = -1
    },
    editField (field, index) {
      this.editFieldIndex = index
      this.fieldsObject = Object.assign({}, field)
    },
    deleteField (index) {
      this.editFieldIndex = index
      this.showDeleteFieldConfirmModal = true
    },
    confirmDeleteField () {
      this.linkAccountDetails.fields.splice(this.editFieldIndex, 1)
      this.showDeleteFieldConfirmModal = false
      if (this.editFieldIndex > -1) {
        this.resetField()
      }
    },
    onResetLinkAccount () {
      this.formSubmitted = false
      this.linkAccountDetails = cloneDeep(this.cloneLinkAccountDetails)
      this.showForm = false
      this.$nextTick(() => {
        this.showForm = true
      })
    },
    sideBarHidden () {
      this.onResetLinkAccount()
      this.resetField()
    },
    validateLinkAccountForm () {
      const accountName = !!this.linkAccountDetails.accountName
      const fields = !!this.linkAccountDetails.fields.length
      const endPointUrl = this.linkAccountDetails.authEndPoint
        ? this.validateUrl(this.linkAccountDetails.authEndPoint)
        : false
      const unLinkEndPointUrl = this.linkAccountDetails.unLinkEndPoint
        ? this.validateUrl(this.linkAccountDetails.unLinkEndPoint)
        : false
      this.scrollToErrorMessage()
      return accountName && fields && endPointUrl && unLinkEndPointUrl
    },
    onSubmitLinkAccount () {
      this.formSubmitted = true
      if (this.validateLinkAccountForm()) {
        this.saveLoadingIcon = true
        const action = this.isEditPage
          ? 'settings/updateLinkAccount'
          : 'settings/addLinkAccount'
        if (!this.isEditPage) {
          this.linkAccountDetails.tenantId = Number(
            this.selectedTenant[0].tenantID
          )
        }
        const postObj = {
          data: this.linkAccountDetails,
          authenticationType: 'link-account'
        }
        this.$store
          .dispatch(action, postObj)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.showToaster(
                false,
                this.isEditPage ? 'UPDATE_LINK_ACCOUNT' : 'ADD_LINK_ACCOUNT',
                'success'
              )
              this.$store.dispatch('settings/getLinkAccounts')
              this.$emit('closeSideBar')
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    },
    setPropertyDependent () {
      if (this.selectedTenant.length && !this.isEditPage) {
        this.linkAccountDetails.isForEachProperty = !!this.selectedTenant[0].isAddressSetupDone
      }
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      this.setPropertyDependent()
    }
  }
}
</script>
